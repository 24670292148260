import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import Strike from '@tiptap/extension-strike';
import Heading from '@tiptap/extension-heading';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import History from '@tiptap/extension-history';
import HardBreak from '@tiptap/extension-hard-break';
import ListItem from '@tiptap/extension-list-item';
import Dropcursor from '@tiptap/extension-dropcursor';
import Link from './Marks/Link';
import Image from './Nodes/Image.js';
import Placeholder from './Extensions/Placeholder';
import ClassDecorator from './Extensions/ClassDecorator';
import Mention from './Nodes/Mention';
import Datetag from './Nodes/Datetag';
import Commands from './Extensions/Commands';
import { getName } from '@/components/Utils';
import Fuse from 'fuse.js';
import SuggestionList from './Nodes/Views/SuggestionList';
import { VueRenderer } from '@tiptap/vue-2';

import tippy from 'tippy.js';
import moment from 'moment';
import * as chrono from 'chrono-node';
import ActionItem from './Nodes/ActionItem.js';
import AgendaItem from './Nodes/AgendaItem.js';
import Reminder from './Extensions/Reminder';
import SpecialItemExtension from './Extensions/SpecialItem';

// import SensitiveSanitizer from './Extensions/SensitiveSanitizer';

export default function EditorExtensions({
  linkErrorCallback = () => {},
  imageErrorCallback = () => {},
  parent = null,
  meetingGuests = [],
  nextMeetingDate = null,
  showSuggestionList = true,
  dateFormat = 'MM/DD/YYYY',
} = {}) {
  return [
    Document.extend({ content: '(block | actionblock | agendablock)+' }), // allow actionblock at toplevel only
    Paragraph.configure({
      HTMLAttributes: { 'data-recording-sensitive': '' },
    }),
    Text,
    Link.configure({ errorCallback: linkErrorCallback }),
    Bold,
    Italic,
    Underline,
    Strike,
    Heading.configure({
      HTMLAttributes: { 'data-recording-sensitive': '' },
    }),
    BulletList.configure({
      HTMLAttributes: { 'data-recording-sensitive': '' },
    }),
    OrderedList.configure({
      HTMLAttributes: { 'data-recording-sensitive': '' },
    }),
    History, 
    HardBreak,
    ListItem.extend({
      content: '(paragraph|actionblock|agendablock) block*',
    }),
    ActionItem,
    AgendaItem,
    SpecialItemExtension,
    Reminder,
    Dropcursor,
    // try without sanitize, should be enough to apply it on doc
    // SensitiveSanitizer,
    Image.configure({
      errorCallback: imageErrorCallback,
    }),
    Placeholder,
    ClassDecorator,
    Datetag.configure({
      dateFormat: dateFormat,
      HTMLAttributes: { class: 'datetag text-grey2 dark:text-grey4' },
      suggestion: {
        dateFormat: dateFormat,
        items: async ({ query }) => {
          const today = moment();
          const todayNum = today.isoWeekday();
          const tomorrow = today.clone().add(1, 'days');
          const tomorrowNum = tomorrow.isoWeekday();
          // Sat and Sun are 6 and 7 respectively
          const disabledDays = [6, 7];
          let items = [];

          if (!disabledDays.includes(todayNum)) {
            items.push({
              idList: today.format('YYYY-MM-DD'),
              nameInsert: 'by today',
              nameList: 'Today',
            });
          }
          if (!disabledDays.includes(tomorrowNum)) {
            items.push({
              idList: tomorrow.format('YYYY-MM-DD'),
              nameInsert: 'by tomorrow',
              nameList: 'Tomorrow',
            });
          }

          // 2 loops to separate this and next week
          for (let i = todayNum; i <= 7; i++) {
            // skip disabled days
            if (disabledDays.includes(i)) continue;

            // today and tomorrow is handled separare, skip
            if (i === todayNum || i === tomorrowNum) continue;
            const day = today.clone().isoWeekday(i);
            items.push({
              idList: day.format('YYYY-MM-DD'),
              nameInsert: 'by ' + day.format('dddd'),
              nameList: day.format('dddd'),
            });
          }
          for (let i = 1; i <= todayNum; i++) {
            // skip disabled days
            if (disabledDays.includes(i)) continue;

            if (i === (todayNum + 1) % 7) continue;

            const day = today.clone().isoWeekday(i + 7);
            items.push({
              idList: day.format('YYYY-MM-DD'),
              nameInsert: 'by next ' + day.format('dddd'),
              nameList: 'Next ' + day.format('dddd'),
            });
          }

          // items contain starting list
          const getFilterItems = (items, nextMeeting) => {
            if (nextMeeting == null) return items;

            let isNextMeetingInIdx = -1;
            let all = items.map((i, index) => {
              if (i.idList === nextMeeting.idList) isNextMeetingInIdx = index;

              return i;
            });
            // append if not present or replace if last elem (for weekly recurring)
            if (isNextMeetingInIdx == -1) all.push(nextMeeting);
            else if (isNextMeetingInIdx == all.length - 1) {
              all[all.length - 1] = nextMeeting;
            }
            return all;
          };
          const specificDate = {
            idList: moment().subtract(1, 'year').format('YYYY-MM-DD'),
            nameList: 'Specific date',
            openPicker: true,
            noDateSuffix: true,
            idInsert: moment().format('YYYY-MM-DD'),
            nameInsert: moment().format('[by] dddd'),
          };
          let nextMeetingDateObj = null;
          if (nextMeetingDate) {
            ////////////////// !!!!!
            nextMeetingDateObj = {
              idList: nextMeetingDate,
              nameInsert: 'by next meeting',
              nameList: 'Next meeting',
            };
          }

          if (!query) {
            const res = nextMeetingDateObj
              ? [...getFilterItems(items, nextMeetingDateObj), specificDate]
              : [...items, specificDate];
            return { all: res };
          }
          const fuse = new Fuse(items, {
            threshold: 0.2,
            keys: ['nameInsert'],
          });

          let textSearch = fuse.search(query).map((item) => item.item);

          const date = predictDate(query);
          const formatted = date ? date.format('YYYY-MM-DD') : null;

          if (date && !textSearch.some((el) => el.idList === formatted)) {
            // do not suggest same date if it is in text-modal already
            textSearch.push({
              idList: formatted,
              nameList: date.format('[by] dddd'),
            });
          }

          const res = nextMeetingDateObj
            ? [...getFilterItems(textSearch, nextMeetingDateObj), specificDate]
            : [...textSearch, specificDate];
          return { all: res };
        },
        render: showSuggestionList ? renderSuggestionList : null,
      },
    }),
    Mention.configure({
      HTMLAttributes: { 'data-recording-sensitive': '' },
      myEmail: parent?.$gAuth?.basicProfile?.getEmail() || '',
      suggestion: {
        items: async ({ query }) => {
          let guests = await Promise.all(
            meetingGuests.map(async (guest) => {
              const meetricName = await getName(guest.email);
              var suggestionName = '';
              if (meetricName == guest.email) {
                suggestionName = guest.displayName || guest.email;
              } else {
                suggestionName = meetricName;
              }
              return {
                idList: guest.email,
                nameList: suggestionName,
                // idInsert: '',
                // nameInsert: ''
              };
            })
          );

          //  console.log('me', this.$gAuth.basicProfile.getEmail());
          guests.sort((a, b) => a.nameList.localeCompare(b.nameList));
          let me = null;
          if (parent?.$gAuth?.isAuthorized && parent?.$gAuth?.basicProfile) {
            const myemail = parent?.$gAuth?.basicProfile?.getEmail();
            guests = guests.filter((item) => {
              if (item.idList !== myemail) return true;
              else {
                me = item;
                return false;
              }
            });
            if (me) {
              me.nameInsert = me.nameList; // what to insert
              me.nameList = `Me (${me.nameList})`; // what to show
              guests.unshift(me);
            }
          }

          if (!query) {
            return { all: guests, me: me };
          }
          const fuse = new Fuse(guests, {
            threshold: 0.2,
            keys: ['nameList'],
          });

          return { all: fuse.search(query).map((item) => item.item), me: me };
        },
        render: showSuggestionList ? renderSuggestionList : null,
      },
    }),
    Commands.configure({
      suggestion: {
        render: showSuggestionList ? renderSuggestionList : null,
      },
    }),
  ];
}

function renderSuggestionList() {
  let component;
  let popup;

  return {
    onStart: (props) => {
      component = new VueRenderer(SuggestionList, {
        parent: this,
        propsData: props,
      });
      popup = tippy('body', {
        getReferenceClientRect: props.clientRect,
        appendTo: () => document.body,
        theme: 'meetric-suggestion',
        content: component.element,
        arrow: false,
        interactive: true,
        showOnCreate: true,
        trigger: 'manual',
        placement: 'bottom-start',
        inertia: true,
        duration: [400, 200],
      });
    },
    onUpdate(props) {
      component.updateProps(props);
      popup[0].setProps({
        getReferenceClientRect: props.clientRect,
      });
    },
    onKeyDown(props) {
      if (props.event.key === 'Escape') {
        popup[0].hide();

        return true;
      }
      return component.ref?.onKeyDown(props);
    },
    onExit() {
      popup?.[0].destroy();
      component?.destroy();
    },
  };
}

// Datetag Helpers START
function isCharNumber(c) {
  return c >= '0' && c <= '9';
}
function predictDate(query) {
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  let chronoSearch = chrono.parseDate(query, tomorrow, {
    forwardDate: true,
  });
  let date = null;
  if (chronoSearch) {
    date = moment(chronoSearch);
  } else {
    // "by 1"
    if (query.length == 1 && isCharNumber(query)) {
      // one char, number
      const num = parseInt(query);
      const today = parseInt(moment().format('dd'));
      if (num > today) {
        date = moment().set('date', num);
      } else {
        date = moment().add(1, 'M').set('date', num);
      }
    }
    // "by 11"
    else if (
      query.length == 2 &&
      isCharNumber(query[0]) &&
      isCharNumber(query[1])
    ) {
      // 2 chars both numbers
      const num = parseInt(query[0] + query[1]);
      const today = parseInt(moment().format('dd'));
      if (num > today) {
        date = moment().set('date', num);
      } else {
        date = moment().add(1, 'M').set('date', num);
      }
    }
  }

  return date;
}
// Datetag Helpers END
