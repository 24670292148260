<template>
  <m-modal
    :show="show"
    :title="title"
    @close="onClose"
    widthClasses="w-full lg:w-3/5"
    :heightStyle="`max-height: ${isMobile ? 75 : 85}vh;`"
  >
    <template v-slot:header_right>
      <span class="text-sm mr-2 hidden lg:inline">ESC to close</span>x
    </template>

    <!-- 85vh for entire modal, title:51px, footer:81px, y padding: 2x21px, roughly 174px -->
    <div
      data-recording-sensitive
      class="flex snippet-modal-content mx-7 flex-col lg:flex-row"
    >
      <div
        class="my-1 flex flex-col content-center w-auto flex-grow overflow-y-auto overflow-x-hidden"
      >
        <div v-if="!actionsLoaded" class="flex text-grey1 font-medium">
          Loading...
        </div>
        <template v-else>
          <template v-if="!hasActions">
            <div class="flex text-grey1 font-medium">
              0 outstanding actions found in past meetings
            </div>
          </template>
          <template v-else>
            <div
              class="text-grey1 font-medium sticky top-0 bg-white dark:bg-base5 z-10 pb-2"
            >
              {{ numberOfActions }} outstanding actions found in past meetings:
            </div>
            <div v-for="(meeting, mid) in actionsLocal" :key="mid">
              <action-item-block
                v-for="action in meeting.actions"
                :key="action.action_id"
                :action="action"
                :eventDetailObject="meeting"
                groupBy="byDate"
                :noLink="true"
                :noGuestHover="true"
                @completedchange="updateActionCompleted($event)"
                @actiondetailshown="showActionDetail($event)"
                :showDetail="openedActionId == action.action_id ? true : false"
              >
                <div v-html="action.innerhtml"></div>
              </action-item-block>
            </div>
          </template>
        </template>
      </div>
    </div>

    <template v-slot:bottom_modal>
      <!-- Left element -->
      <div class="flex">
        <div class="flex flex-col text-base justify-center">
          <a-link
            href="https://meetric.notion.site/Leftovers-outstanding-action-items-f459a10a3da54340b89f2603bbf53633"
            segmentName="Get help"
            >Get help</a-link
          >
        </div>
      </div>
      <!-- Right element -->
      <div class="flex justify-between" data-tooltip="moveToNotes">
        <m-button
          button-style="btn-primary"
          :class="!hasActions ? 'opacity-50' : ''"
          icon="✅"
          :pending="buttonPending"
          @click="onMoveToMeetingClick"
          :disabled="!hasActions"
        >
          Move all to this meeting
        </m-button>
        <hover-tool-tip
          label="Move actions to the current meeting"
          selector='[data-tooltip="moveToNotes"]'
          placement="top"
        ></hover-tool-tip>
      </div>
    </template>
  </m-modal>
</template>

<script>
import HoverToolTip from '@/components/UI/HoverToolTip';
import ActionItemBlock from '@/components/ActionItemBlock';
import MButton from '@/components/UI/MButton';
// import MRadio from '@/components/UI/MRadio';
import MModal from '@/components/UI/MModal';
// import SnippetEditor from '@/components/Editor2Snippet';
import ALink from '@/components/UI/ALink.vue';
// import MPlaceholder from '@/components/UI/MPlaceholder';
// import {
//   getAgendaTemplates,
//   getCustomSnippets,
//   setCustomSnippet,
//   deleteCustomSnippet,
//   getIcebreakers,
// } from '@/api/Cherry';
import { updateAction } from '@/api/Hocuspocus';

export default {
  name: 'LeftoversModal',
  components: {
    MButton,

    MModal,
    ActionItemBlock,
    HoverToolTip,
    ALink,
  },
  props: {
    html: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    actions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      openedActionId: '',
      actionsLocal: {},
      buttonPending: false,
    };
  },

  watch: {
    actionsLoaded: {
      handler(val) {
        if (val) {
          // local copy so we can change
          this.actionsLocal = this.actions;
        }
      },
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    updateActionCompleted({ completed, docID, actionID, isPrivate }) {
      const ai = this.actionsLocal[docID]?.actions?.find(
        (e) => e?.action_id === actionID
      );
      if (!actionID || !docID || !ai) {
        this.$snack('Error, please try to refresh the page');
        return;
      }

      const payload = {
        mid: docID,
        cid: this.actionsLocal[docID]?.cid || '',
        email: this.getEmail, // my email, needed only for private note
        completed: completed,
        uuid: actionID,
        private: isPrivate,
      };

      this.$set(ai, 'action_pending', true);

      updateAction(payload).then((r) => {
        this.$set(ai, 'action_pending', false);

        if (r?.success) {
          this.$set(ai, 'action_completed', completed);
          this.$set(ai, 'update_counter', ++ai.update_counter);

          if (completed) {
            ai.outerhtml = ai.outerhtml.replace(
              `data-completed="false"`,
              `data-completed="true"`
            );
          } else {
            ai.outerhtml = ai.outerhtml.replace(
              `data-completed="true"`,
              `data-completed="false"`
            );
          }

          const trackProps = {
            source: 'leftovers-modal',
            value: completed,
            action_uuid: actionID,
            meeting_id: docID,
            isPrivate: isPrivate,
          };
          window.meetric.track('Toggle action status', trackProps);
          return;
        }

        // ERRORS
        // change back to opposite value if error, this is
        if (r?.error?.code == 403) {
          this.$set(ai, 'update_counter', ++ai.update_counter);
          this.$snack('Authentication fail, please try to refresh the page');
        } else if (r?.error?.code == 404) {
          this.$snack(
            'Oops! We could not find that action. Please close and open the Leftover window to reload the actions'
          );
        } else if (r?.error) {
          this.$set(ai, 'update_counter', ++ai.update_counter);
          this.$snack('Error, please try to refresh the page');
        }
      });
    },

    showActionDetail(event) {
      if (this.openedActionId == event) {
        this.openedActionId = '';
      } else {
        this.openedActionId = event;
      }
    },
    onClose() {
      this.$emit('close');
    },
    onMoveToMeetingClick() {
      this.buttonPending = true;

      const result = [];
      Object.keys(this.actionsLocal).forEach((mid) => {
        this.actionsLocal[mid]?.actions?.forEach((action) => {
          result.push(action.outerhtml);
        });
      });

      this.$emit('insertActions', {
        actionsHTML: result,
        actions: this.actionsLocal,
      });
      setTimeout(() => {
        this.buttonPending = false;
      }, 500);

      window.meetric.track('Move to this meeting', {
        actionsNum: result.length,
      });
    },
  },
  computed: {
    numberOfActions() {
      let total = 0;
      for (const prop in this.actions) {
        total += this.actions[prop]?.actions?.length || 0;
      }
      return total;
    },
    getEmail() {
      return this.$gAuth?.basicProfile?.getEmail() || '';
    },
    actionsLoaded() {
      if (!Object.keys(this.actions).length) return true;

      //we have keys
      const x = Object.values(this.actions).every((x) => x !== null);

      return x;
    },
    hasActions() {
      for (const prop in this.actions) {
        if (this.actions[prop]?.actions?.length) return true;
      }

      return false;
    },
    isMobile() {
      return this.$store?.getters['isMobile'];
    },
    accountPlan() {
      return this.$store?.getters['plan'];
    },
  },
};
</script>
<style scoped>
.snippet-modal-content {
  /* 80vh for entire modal, title:51px, footer:77px, y padding: 2x21px, roughly 170px  */
  max-height: calc(75vh - 171px);
}
@screen lg {
  .snippet-modal-content {
    /* 80vh for entire modal, title:51px, footer:81px, y padding: 2x21px, roughly 175px  */
    max-height: calc(85vh - 175px);
  }
}

.left-title {
  @apply text-grey1 font-medium mb-2;
}
.placeholder-wrapper {
  @apply flex text-base mb-2;
  line-height: 1.5;
}
.placeholder-wrapper::before {
  content: '\200b';
}
.placeholder {
  @apply rounded-lg my-auto w-5/6;
  height: 1rem;
}

#template-select >>> select {
  width: 150px;
}

.agenda-options span {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  margin-top: 4px;
}

.agenda-options span.icon-g {
  background-image: url('~@/assets/logos/g-logo.webp'),
    url('~@/assets/logos/g-logo.png');
}
.agenda-options span.icon-m {
  background-size: 99%;
  margin-right: -0.5rem;
  background-image: url('~@/assets/logos/meetric-logo.svg');
  filter: invert(52%) sepia(82%) saturate(2237%) hue-rotate(150deg)
    brightness(87%) contrast(104%);
}

.agenda-options div.inactive {
  opacity: 0.6;
  filter: grayscale(100%);
}

.templates-content >>> h1 {
  @apply text-2xl font-medium;
  letter-spacing: 0.02rem;
  margin-top: 1.3rem;
  margin-bottom: 9px;
}
.templates-content >>> h2 {
  @apply text-xl font-medium;
  letter-spacing: 0.02rem;
  margin-top: 1rem;
  margin-bottom: 6px;
}
.templates-content >>> h3 {
  @apply text-lg font-medium;
  letter-spacing: 0.02rem;
  margin-top: 1rem;
  margin-bottom: 9px;
}
.templates-content >>> h1:first-child,
.templates-content >>> h2:first-child,
.templates-content >>> h3:first-child {
  margin-top: 0;
}
.templates-content >>> a {
  @apply underline cursor-pointer;
}
.templates-content >>> p {
  min-height: 10px;
}
.templates-content >>> p:empty {
  min-height: 22px;
}
</style>
