<template>
  <div class="editor" v-if="editor" @mousedown.self="editor.commands.focus()">
    <div v-show="loaded">
      <div
        v-if="!inRelatedMeeting && (!readOnly || isEraOneReadOnly)"
        class="sticky top-10 lg:top-8 z-20 lg:z-10"
      >
        <div class="h-0.5 lg:h-1 bg-white dark:bg-base5"></div>
        <div class="flex bg-white dark:bg-base5 w-full mb-0">
          <editor-menu-bar :editor="editor" :eventID="meetingId">
          </editor-menu-bar>
          <slot></slot>
        </div>
        <div class="h-2 lg:h-0 bg-white dark:bg-base5"></div>
        <div class="h-1.5 lg:h-1.5 bottom-fade"></div>
      </div>

      <editor-floating-menu :editor="editor" v-if="!readOnly" />

      <editor-content
        v-if="loaded"
        data-recording-sensitive
        :editor="editor"
        class="prose prose-sm min-h-full py-3"
        id="meetriceditor"
        :class="{
          'filter-show-actions': showOnlyActions,
          'pt-0 lg:pt-2': !inRelatedMeeting && !readOnly, // when toolbar displayed
        }"
      />
      <template v-if="$slots.quickstart">
        <template
          v-if="editor && editor.isEmpty && !editor.isFocused && !readOnly"
        >
          <slot name="quickstart"></slot>
        </template>
      </template>
    </div>

    <!-- <template v-else>
      <div v-if="!inRelatedMeeting" class="sticky top-10 lg:top-8 z-20 lg:z-10">
        <div class="h-0.5 lg:h-1 bg-white dark:bg-base5"></div>
        <div class="flex justify-between bg-white dark:bg-base5 w-full mb-0">
          <div class="flex pt-1 lg:pt-0 mt-2 mb-1">
            <m-placeholder
              class="h-6 rounded-lg my-auto"
              style="width: 20rem"
            ></m-placeholder>
          </div>
          <div class="hidden lg:flex">
            <m-placeholder
              class="h-8 rounded-lg my-auto w-28 mr-2"
            ></m-placeholder>
            <m-placeholder
              class="h-8 rounded-lg my-auto w-28 mr-4"
            ></m-placeholder>
            <m-placeholder
              class="h-8 rounded-lg my-auto"
              style="width: 11rem"
            ></m-placeholder>
          </div>
        </div>
        <div class="h-2 lg:h-0 bg-white dark:bg-base5"></div>
        <div class="h-1.5 lg:h-1.5 bottom-fade"></div>
      </div>
      <div
        class="py-3"
        :class="{
          'pt-0 lg:pt-2': !inRelatedMeeting && !readOnly, // when toolbar displayed
        }"
      >
        <m-placeholder class="h-3 mt-1 rounded-lg my-auto"></m-placeholder>
        <m-placeholder class="h-3 mt-4 rounded-lg my-auto"></m-placeholder>
        <m-placeholder class="h-3 mt-4 rounded-lg my-auto"></m-placeholder>
      </div>
    </template> -->
  </div>
</template>

<script>
import EditorMenuBar from './Menu/EditorMenuBar';
import EditorExtensions from './extensions';
import { Editor, EditorContent } from '@tiptap/vue-2';
import EditorFloatingMenu from './Menu/EditorFloatingMenu.vue';

import Collaboration from '@tiptap/extension-collaboration';
import * as Y from 'yjs';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import { debounce } from 'debounce';
import { getMeetingPassword, getCountFromDoc } from '@/components/Utils';
import { HocuspocusProvider, MessageType } from '@hocuspocus/provider';
import { findChildren } from '@tiptap/core';

import { DOMSerializer } from 'prosemirror-model';
export default {
  components: {
    EditorContent,
    EditorMenuBar,
    EditorFloatingMenu,
  },
  props: {
    meetingId: {
      type: String,
      required: true,
    },
    isPrivate: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPublicRead: {
      type: Boolean,
      required: false,
      default: false,
    },
    calendarId: {
      type: String,
      required: true,
    },
    summary: {
      type: String,
      required: false,
      default: 'Unknown meeting',
    },
    user: {
      // for collab
      type: Object,
      required: false,
      default: () => ({ name: '', color: '#FF9633' }),
    },

    nextMeetingDate: {
      type: String,
      required: false,
      default: '',
    },
    showOnlyActions: {
      type: Boolean,
      required: false,
      default: false,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    inRelatedMeeting: {
      type: Boolean,
      required: false,
      default: false,
    },
    areThereRelatedMeetings: {
      type: Boolean,
      required: false,
      default: false,
    },
    timemachine: {
      type: Boolean,
      required: false,
      default: false,
    },
    guests: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
    addHTMLToNotes: {
      type: Number,
      required: false,
      default: 0,
    },
    insertHTML: {
      type: String,
      required: false,
      default: '',
    },
    getNote: {
      type: Number,
      required: false,
      default: 0,
    },
    getIncompleteActions: {
      type: Number,
      required: false,
      default: 0,
    },
    actionsToRemoveCounter: {
      type: Number,
      required: false,
      default: 0,
    },
    actionsToRemove: {
      type: Object,
      required: false,
      default: null,
    },
  },
  watch: {
    actionsToRemoveCounter: function () {
      this.removeActions(this.actionsToRemove);
    },
    meetingId: function () {
      // id changed, need to load correct editor

      this.editor?.destroy();
      this.provider?.destroy();
      this.initEditor();
    },
    addHTMLToNotes: function () {
      this.insertIntoEditor(this.insertHTML);
    },
    getNote: function () {
      // replace single BR with 2 so it is actually shown in emails
      this.$emit('noteHTML', {
        notes: this.editor.getHTML().replace(/<br>/g, '<br><br>'),
        agenda_items: this.getAgendaItemsHTML(),
      });
    },
    getIncompleteActions: function () {
      this.$emit('incompleteactions', {
        mid: this.meetingId,
        actions: this.getActions(),
        private: this.isPrivate,
      });
    },

    readOnly: function (val) {
      this.editor?.setOptions({ editable: !val });
    },
    isPublicRead: function () {
      // this is needed as the helper text wouldn't change after init
      this.editor?.setOptions({ emptyEditorText: this.getEmptyEditorText });
    },
  },
  data() {
    return {
      editor: null,
      ydoc: null,
      provider: null,
      indexdb: null,
      docName: '',
      ACL: null,
      debouncedActionCount: null,
      debouncedAwareness: null,
      collabTracked: false,
      loaded: false,
      charCountOnInit: 0, // char count when we load document
      charCountReceived: 0, // char count recieved from server, used to find out if we got any text after init
      blockerUnwatchFn: null,
    };
  },
  computed: {
    getEmptyEditorText() {
      if (this.isPrivate)
        return 'Write notes here for your eyes only or pick an option below...';
      if (this.isPublicRead) return 'Write notes here for everyone to see';

      //not private and not public
      return 'Write notes here for all meeting guests or pick an option below...';
    },
    isEraOneReadOnly() {
      const plan = this.$store.getters['plan'];
      const era = this.$store.getters['era'];
      const trialConsumed = this.$store.getters['trialConsumed'];
      if (plan == 0 && era == 1 && trialConsumed) {
        return true;
      }
      return false;
    },
    getEmail() {
      return this.$gAuth?.basicProfile?.getEmail() || '';
    },
  },
  mounted() {
    this.initEditor();

    this.blockerUnwatchFn = this.$store.watch(
      (state, getters) => getters.isSessionBlocked,
      () => {
        // disconnect provider without reconnection when blocker is on
        this.provider?.disconnect();
      }
    );
  },

  methods: {
    // actions, cid,event,mid,private
    removeActions({ actions = [] }) {
      if (!Array.isArray(actions) || !actions.length) return;

      // console.log('remove actions', actions);

      let totalRemoved = 0;

      this.editor.commands.forEach(actions, (a, { tr, commands }) => {
        const item = findChildren(tr.doc, (node) => {
          return (
            node.type.name === 'action_item' && a.action_id === node.attrs.uuid
          );
        })?.[0];

        if (!item) {
          return true;
        }

        totalRemoved++;
        // console.log('found action', item.node.attrs.uuid);
        return commands.deleteRange({
          from: item.pos,
          to: item.pos + item.node.nodeSize,
        });
      });

      if (totalRemoved) {
        this.$emit('actionsRemoved', { count: totalRemoved });
      }
    },
    getActions(onlyIncomplete = true) {
      const actions = [];
      this.editor?.state.doc.descendants((node) => {
        if (node.type?.name === 'action_item') {
          // empty action_item has size of 2, don't show empty ones
          if (node.content?.size > 2) {
            const duedate = node.attrs.dueDate ? node.attrs.dueDate : '';
            const owner_email = node.attrs.owner ? node.attrs.owner : '';

            if (onlyIncomplete && node.attrs.completed) return;

            actions.push({
              action_owner_email: owner_email,
              action_duedate: duedate,
              update_counter: 0,
              action_pending: false,
              doc_id: this.meetingId,
              private: this.isPrivate,
              action_id: node.attrs.uuid,
              node: node,
              innerhtml: DOMSerializer.fromSchema(
                node.type.schema
              ).serializeNode(node).innerHTML,
              outerhtml: DOMSerializer.fromSchema(
                node.type.schema
              ).serializeNode(node).outerHTML,
              action_completed: node.attrs.completed,
            });
          }
        }
      });
      return actions;
    },
    getAgendaItemsHTML: function () {
      let html = '';
      this.editor.view.state.doc.descendants((node, pos) => {
        if (node.type?.name === 'agenda_item') {
          const wrap = document.createElement('div');
          const depth = this.editor.view.state.doc.resolve(pos).depth;
          const ser = DOMSerializer.fromSchema(node.type.schema).serializeNode(
            node
          );
          wrap.appendChild(ser);
          // normalize depth so no list is 0 and top level list is 0 as well
          const agendaDepth = (depth === 0 ? 2 : depth) - 2;
          wrap.dataset.agendadepth = agendaDepth;
          // depth is even number so indentation is multiple of 28px
          wrap.style.marginLeft = agendaDepth * 14 + 'px';
          html += wrap.outerHTML;
        }
      });
      return html;
    },
    initEditor() {
      // const URL = 'ws://localhost:8081';
      const URL = process.env.VUE_APP_WSS_URL;

      this.docName = `${this.isPrivate ? this.getEmail + ':' : ''}${
        this.meetingId
      }`;

      this.ydoc = new Y.Doc();

      // get password from url if exists
      const password = !this.inRelatedMeeting
        ? getMeetingPassword(window.location.search)
        : null;

      const params = {
        cid: this.calendarId,
        private: this.isPrivate,
        p: password || '',
      };

      this.provider = new HocuspocusProvider({
        url: URL,
        name: this.docName,
        document: this.ydoc,
        parameters: params,
        reconnectTimeoutBase: 1500,
        maxReconnectTimeout: 3000,
        token:
          this.$gAuth?.GoogleAuth?.currentUser?.get().getAuthResponse()
            ?.access_token || 'x', // can't pass empty
        debug: true,
        forbiddenErrorCount: 0,

        onSynced: () => {
          // console.log('synced', state);
          this.loaded = true;
          this.$emit('loaded');
          this.charCountOnInit = this.editor.getCharacterCount(); // to determine if document was empty

          this.countActions(this.editor.getJSON());

          this.editor.on('update', ({ editor }) => {
            // console.log('update');
            this.$emit('updated');
            // count the actions and notes for the panel header
            this.countActions(editor.getJSON());
          });
        },
        onAuthenticated: () => {
          this.provider.options.forbiddenErrorCount = 0;
        },
        onAuthenticationFailed: () => {
          // forbidden from server
          console.log(
            'ws forbidden',
            this.provider.options.forbiddenErrorCount
          );

          // on forbidden, refresh token once
          // either no permission or token expired
          if (this.provider.options.forbiddenErrorCount >= 3) {
            // max 3 tries
            // TODO: do something if meeting is forbidden 3x
            this.$emit('forbidden', { id: this.meetingId });
            return;
          }

          this.provider.options.forbiddenErrorCount++;

          const gUser = this.$gAuth?.GoogleAuth?.currentUser?.get();
          if (gUser) {
            const expiresAt = gUser.getAuthResponse().expires_at;
            const now = new Date().valueOf();
            if (expiresAt <= now) {
              // emit message so it can be renewed
              this.$emit('tokenExpired');
            }
          }

          setTimeout(() => {
            this.provider.options.token =
              this.$gAuth?.GoogleAuth?.currentUser?.get().getAuthResponse()
                ?.access_token || 'x'; // can't pass empty
            this.provider.connect();
          }, 2000 + Math.floor(Math.random() * 300));
        },
        // onClose: ({ event }) => {
        //   console.log(
        //     'ws close',
        //     this.isPrivate,
        //     event,
        //     this.provider.failedConnectionAttempts
        //   );
        // },
      });

      // Bind temporarly so we can track CREATE/EDIT note
      this.provider.on('message', this.onIncomingMessage);
      this.provider.on('outgoingMessage', this.onOutgoingMessage);

      const ext = [
        ...EditorExtensions({
          parent: this,
          meetingGuests: this.guests,
          nextMeetingDate: this.nextMeetingDate,
          linkErrorCallback: this.linkErrorCallback,
          imageErrorCallback: this.imageErrorCallback,
          showSuggestionList: true,
          dateFormat: 'MM/DD/YYYY',
        }),
        Collaboration.configure({
          document: this.ydoc,
        }),
        CollaborationCursor.configure({
          provider: this.provider,
          user: this.user,
          onUpdate: (users) => {
            this.onAwarenessChange(users);
          },
        }),
      ];

      this.editor = new Editor({
        editable: !this.readOnly,
        extensions: ext,
        editorProps: {
          attributes: { 'data-recording-sensitive': '' },
        },
        emptyEditorText: this.getEmptyEditorText,
        isPrivate: this.isPrivate,
        meetingId: this.meetingId,
        calendarId: this.calendarId,
        summary: this.summary,
        inRelatedMeeting: this.inRelatedMeeting,
      });
    },
    onOutgoingMessage({ message }) {
      if (
        message.type == MessageType.Sync &&
        message.description == 'A document update'
      ) {
        this.trackFirstChange(this.charCountOnInit, this.charCountReceived);

        this.provider.off('outgoingMessage', this.onOutgoingMessage);
        this.provider.off('message', this.onMessage);
      }
    },
    onIncomingMessage({ message }) {
      // char count used t odetermine if there was any external change
      if (message.type == MessageType.Sync) {
        this.charCountReceived = this.editor.getCharacterCount();
      }
    },
    onAwarenessChange(users) {
      const awarenessFn = () => {
        this.$emit('participants', users);

        if (!this.collabTracked && users.length > 1) {
          this.trackCollab(users);
        }
      };

      this.debouncedAwareness?.clear();
      this.debouncedAwareness = debounce(awarenessFn, 3000);
      this.debouncedAwareness();
    },

    trackFirstChange(charCountOnInit, charCountReceived) {
      let organizerCount = this.guests.filter((g) => g.organizer).length;
      let onlyGuestsCount = 0;
      if (organizerCount > 0) {
        onlyGuestsCount = this.guests.filter(
          (g) => !g.organizer && g.email != 'notes@meetric.app'
        ).length;
      } else {
        onlyGuestsCount = this.guests.filter(
          (g) => !g.organizer && !g.self && g.email != 'notes@meetric.app'
        ).length;
      }

      console.log(this.guests);
      console.log(onlyGuestsCount);
      const props = {
        meeting_id: this.meetingId,
        inRelatedMeeting: this.inRelatedMeeting,
        isPrivate: this.isPrivate,
        recurring: this.nextMeetingDate != '',
        timemachine: this.areThereRelatedMeetings,
        guests: onlyGuestsCount,
      };

      // if document was 0 chars on init and 0 chars on update (no change), track as CREATE
      if (charCountReceived == 0 && charCountOnInit == 0) {
        window.meetric.track('Create note', props);
      } else {
        window.meetric.track('Edit note', props);
      }
    },
    trackCollab(users) {
      const unique = users.reduce(function (result, key) {
        const email = key.email || 'anonymous';
        result[email] = true;
        return result;
      }, {});
      if (Object.keys(unique).length > 1) {
        this.collabTracked = true;
        // this is called once per meeting when collab=2 or more users
        // console.log('track collab');
        const props = {
          meeting_id: this.meetingId,
          inRelatedMeeting: this.inRelatedMeeting,
          isPrivate: this.isPrivate,
        };
        window.meetric.track('Collab', props);
      }
    },
    insertIntoEditor(textHTML) {
      if (textHTML.trim().length === 0) return false;

      const html = textHTML.trim().replace(/<br><\/p>/g, '</p>');
      const { empty, $anchor } = this.editor.state.selection;
      if (empty) {
        if (this.editor.isEmpty) {
          this.editor.chain().setContent(html).focus().run();
        } else {
          if (
            $anchor.depth == 1 &&
            $anchor.parent.type.name == 'paragraph' &&
            $anchor.parent.nodeSize == 2
          ) {
            // empty top level paragraph
            this.editor
              .chain()
              .joinBackward()
              .insertContent(html)
              .focus()
              .run();
          } else {
            this.editor.chain().insertContent(html).focus().run();
          }
        }
      }
    },
    countActions(JSONdoc) {
      const countFn = () => {
        const count = getCountFromDoc(JSONdoc);
        this.$emit('editorCount', count);
      };

      this.debouncedActionCount?.clear();
      this.debouncedActionCount = debounce(countFn, 1000);
      this.debouncedActionCount();
    },
    linkErrorCallback({ type }) {
      if (type == 'image') {
        this.$snack('Links cannot be added to images');
      }
    },
    imageErrorCallback({ type }) {
      if (type == 'localpath') {
        this.$snack('Image not accessible... try copy-paste!');
      }
    },
  },
  beforeDestroy() {
    this.editor?.destroy();
    this.provider?.destroy();
    this.blockerUnwatchFn?.();
  },
};
</script>
<style scoped>
.bottom-fade {
  background: linear-gradient(
    180deg,
    rgba(var(--white), 1) 0%,
    rgba(var(--white), 0) 100%
  );
}
.dark .bottom-fade {
  background: linear-gradient(
    180deg,
    rgba(var(--base-05), 1) 0%,
    rgba(var(--base-05), 0) 100%
  );
}
</style>

<style>
.prose a {
  @apply text-grey2;
}
.dark .prose a {
  @apply text-grey4;
}
.prose a {
  @apply underline cursor-pointer hover:opacity-75;
}
span.savestate:empty:before {
  content: '\200b';
}

/* Notes formatting */
.prose-sm {
  @apply leading-normal;
}

.prose h1,
.prose .agenda-item > .agenda-content > h1 {
  /* @apply text-4xl font-medium;
  letter-spacing: 0.02rem;
  margin-top: 1.2rem;
  margin-bottom: 9px; */
  @apply text-2xl font-medium;
  letter-spacing: 0.02rem;
  margin-top: 1.3rem;
  margin-bottom: 9px;
}
.prose h2,
.prose .agenda-item > .agenda-content > h2 {
  /* @apply text-3xl font-medium;
  letter-spacing: 0.02rem;
  margin-top: 1.1rem;
  margin-bottom: 9px; */
  @apply text-xl font-medium;
  letter-spacing: 0.02rem;
  margin-top: 1rem;
  margin-bottom: 6px;
}
.prose h3 {
  @apply text-lg font-medium;
  letter-spacing: 0.02rem;
  margin-top: 1rem;
  margin-bottom: 9px;
}
.prose h1:first-child,
.prose h2:first-child,
.prose h3:first-child {
  margin-top: 0;
}

.prose li > div[data-type='action_item'],
.prose li > div[data-type='agenda_item'] {
  margin-left: -1.65rem;
}
.prose li.specialitem-child {
  @apply list-none;
  counter-increment: none;
}
/* .prose li.actionitem-child::before {
  content: '';
} */

ul {
  @apply list-outside;
  margin-left: 1.5rem;
}
ol {
  @apply list-outside;
  margin-left: 1.67rem;
  list-style: none;
  counter-reset: ol-counter;
}
ul > li {
  @apply list-disc;
  padding-left: 0.15rem;
}
ol > li {
  position: relative;
  counter-increment: ol-counter;
}
ol > li:not(.specialitem-child)::before {
  width: 1.67rem;
  text-align: right;
  position: absolute;
  left: -2.2rem;
  word-spacing: -0.15rem;
  content: counter(ol-counter) ' .';
}

/* ul > li > ul,
ol > li > ol,
ol > li > ul,
ul > li > ol,
ul > li,
ol > li {
  margin-bottom: 0.2rem;
} */

/* everything in the editor except for action-item */
/* .activeMeetingEditor
  .filter-show-actions
  > div.ProseMirror
  > *:not(.action-item) {
  display: none;
} */
.activeMeetingEditor
  .filter-show-actions
  > div.ProseMirror
  > *:not(.action-item):not(ul, ol) {
  display: none;
}

.activeMeetingEditor .filter-show-actions > div.ProseMirror li {
  visibility: hidden;
}

.activeMeetingEditor .filter-show-actions > div.ProseMirror li > p,
.activeMeetingEditor
  .filter-show-actions
  > div.ProseMirror
  li.agendaitem-child
  > div {
  height: 0;
  margin-bottom: 0;
}

.activeMeetingEditor
  .filter-show-actions
  > div.ProseMirror
  li.actionitem-child {
  visibility: visible;
  margin-bottom: 0.2rem;
}

/* Action items */
.todo-checkbox,
.agenda-radio {
  @apply mt-0.5;
  width: 1.15rem;
  height: 1.15rem;
  margin-right: 0.5rem;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}
.agenda-item.with-h1 > .agenda-radio {
  margin-top: 1.7rem;
}
.agenda-item.with-h2 > .agenda-radio {
  margin-top: 1.3rem;
}

.todo-content > p:last-of-type,
.agenda-content > p:last-of-type {
  margin-bottom: 0;
}

.todo-content,
.agenda-content {
  @apply lg:text-base text-lg w-full;
}

div[data-completed='true'] > .todo-content > p,
div[data-completed='true'] > .agenda-content > p,
div[data-completed='true'] > .agenda-content > h1,
div[data-completed='true'] > .agenda-content > h2 {
  @apply text-grey3 line-through;
}
div[data-completed='true'] > .todo-content img,
div[data-completed='true'] > .agenda-content img {
  @apply opacity-75;
}
div[data-completed='true'] > .todo-content .mention,
div[data-completed='true'] > .todo-content .datetag,
div[data-completed='true'] > .todo-content .hashtag,
div[data-completed='true'] > .agenda-content .mention,
div[data-completed='true'] > .agenda-content .datetag {
  @apply text-grey4;
}
div[data-completed='false'] {
  text-decoration: none;
}

.mention {
  @apply text-base2;
}
.mention.myself {
  @apply text-base3;
}

.ProseMirror {
  @apply text-grey1;
}

.ProseMirror p {
  @apply text-lg lg:text-base;
}
.ProseMirror strong,
.ProseMirror b {
  @apply font-semibold;
}

/* ProseMirror adds <img> at the end of line on IOS devices to fix cursor issues.
   Must be inline to override default (block) */
.ProseMirror p img:not([src]),
.ProseMirror h1 img:not([src]),
.ProseMirror h2 img:not([src]),
.ProseMirror h3 img:not([src]) {
  display: inline;
}

.prose p.is-editor-empty:first-child::before {
  content: attr(data-empty-text);
  pointer-events: none;
  height: 0;
  @apply font-normal text-grey4;
}
.dark .prose p.is-editor-empty:first-child::before {
  @apply text-opacity-40;
}
.prose .ProseMirror-focused p.is-empty:before {
  @apply text-grey4 text-lg lg:text-base;
  content: "Type '/' to power up your notes";
  float: left;
  pointer-events: none;
  height: 0;
}

.prose-sm .action-item,
.prose-sm .agenda-item {
  @apply mb-1;
}

.prose-sm p {
  @apply mb-1;
}
.ProseMirror:focus {
  outline: none;
}

.editor {
  position: relative;
}

/* cursor */
.collaboration-cursor__caret {
  position: relative;
  margin-left: -1px;
  margin-right: -1px;
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  word-break: normal;
  opacity: 0.6;
  z-index: 9;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
.collaboration-cursor__caret:hover {
  opacity: 0.85;
}
/* Render the username above the caret */
.collaboration-cursor__label {
  @apply text-sm font-medium;
  /* opacity: 0.7; */
  position: absolute;
  top: -1.2rem;
  left: -1px;
  user-select: none;
  color: #fff;
  padding: 0rem 0.3rem;
  border-radius: 4px 4px 4px 0px;
  white-space: nowrap;
}

/* different for first line as cursor is mostly hidden */
.ProseMirror > p:first-child .collaboration-cursor__label,
.ProseMirror > div.action-item:first-child .collaboration-cursor__label {
  top: -0.8rem;
}

.image-loader {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.4;
  }
}
</style>
